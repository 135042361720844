import React from "react";
import { Link } from "react-router-dom";
import "./../../../sass/Error.scss";
import MaskImage from './../../../images/Mask group 1.png' 
const Error = () => {
  return (
    <div id="error">
      <div className="error_404">
        <p>404</p>
      </div>
      <Link to="/">
        <div className="center">
          {/* <span>Ana səhifə</span> */}
          {/* <img src={MaskImage} alt="MaskImage" /> */}
          <p>Ana Səhifə</p>
        </div>
      </Link>
      <div className="error_4044">
        <p>404</p>
      </div>
    </div>
  );
};

export default Error;
