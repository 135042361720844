import React from "react";

import Home from "./Components/Pages/Home/Home";

import Error from "../src/Components/Pages/Error/Error";
import Suggest from "./Components/Pages/Suggest/Suggest";

const Router2 = [
    {
        id: 1,
        path: "/",
        exact: "true",
        component: <Home />,
        // fallback: <Home />,
    },
    {
        path: "/suggest",
        exact: "true",
        component: <Suggest />,
    },
    
    {
        path: "*",
        exact: "true",
        component: <Error />,
    },
];

export default Router2;
