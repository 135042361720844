import React from 'react'
import './sass/Submit.scss'
const Submit = () => {
    return (
        <button type='submit' id='submit'>
            Göndər 
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path d="M15.4757 12.4773C15.0524 12.4773 14.7014 12.1263 14.7014 11.7031L14.7014 2.97022L1.56892 16.1005C1.41406 16.2554 1.2179 16.3276 1.02174 16.3276C0.825576 16.3276 0.629414 16.2554 0.474551 16.1005C0.175147 15.8012 0.175147 15.3057 0.474551 15.0063L13.607 1.87602L4.87268 1.87602C4.44939 1.87602 4.09836 1.52506 4.09836 1.10183C4.09836 0.678603 4.44939 0.327636 4.87268 0.327636L15.4757 0.327637C15.5789 0.327637 15.6718 0.348282 15.7751 0.389572C15.9609 0.46183 16.1158 0.616669 16.1881 0.802475C16.2294 0.905701 16.25 0.998604 16.25 1.10183L16.25 11.7031C16.25 12.1263 15.899 12.4773 15.4757 12.4773Z" fill="#B2B2B2" stroke="#121212" stroke-width="0.5" />
            </svg>
        </button>
    )
}

export default Submit