import React from 'react'
import "./../../../sass/Success.scss";
import { useNavigate } from 'react-router-dom';
const Success = (props) => {
    const navigate = useNavigate()
    return (
        <div id='success'>Success
            <p>
                {}
            </p>
        </div>
    )
}

export default Success