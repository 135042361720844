import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Router2 from "../src/router";
import Spline from '@splinetool/react-spline';
import "./sass/App.scss"
import AnimatedCursor from "./Components/Animation/Cursor"
import Firstextsect from './Components/Pages/Home/Firstextsect';

export default function App() {

  return (
    <>
      <div className="App">
        <AnimatedCursor />
        {/* <Spline className="spline-container" scene="https://prod.spline.design/8xeFBFOozjHkVaye/scene.splinecode" /> */}
        {/* <Router> */}
          <Routes>
            {Router2 && Router2.map((el, index) => (
              <Route key={index} path={el.path} element={el.component} />
            ))}
          </Routes>
        {/* </Router> */}
      </div>
    </>
  );
}
