import React from "react";
import AnimatedCursor from "react-animated-cursor";
const Cursor = () => {
  return (
    <>
      {" "}
      <AnimatedCursor
        innerSize={20}
        outerSize={200}
        color=""
      
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={1}
        outerStyle={{
          backgroundColor: "transparent",
          border: "1px solid white",
          boxShadow: " 0px 0px 0px 0px"
        }}
        innerStyle={{
          backgroundColor: "#7f5cca",
          // mixBlendMode: "overlay", // Blend mode for inner cursor
         
          zIndex: 999999, // Ensure inner cursor is on top of other elements
          
        }}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
    </>
  );
};

export default Cursor;
