import React, { useState } from "react";
// import styled from "styled-components";
// import Select from 'react-select';
import "./../../sass/Navbar.scss";
import Logo from "./../../images/Logo.svg";
// import { FaBars } from "react-icons/fa";
import { Spin as Hamburger } from "hamburger-react";
import Header from "../../Components/Pages/Home/Header";
import { Link } from "react-router-dom";


const Navbar = ({ slideToSection }) => {
  const [currentLanguage, setCurrentLanguage] = useState("ENG");

  const handleLanguageChange = (event) => {
    setCurrentLanguage(event.target.value);
  };

  const [isOpen, setOpen] = useState(false);

  // const handleSlideToSection = (sectionIndex) => {
  //   slideToSection(sectionIndex);
  //   setOpen(false); // Close the mobile menu after sliding
  // }

  // const [toggle, toggleNav] = useState(false);

  const handleClickNav = (listName) => {
    slideToSection(listName);
    console.log(listName);
  };

  return (
    <>
      <nav id="nav">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3 col-md-2">
              <div className="left_side">
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-9 normal_part col-md-10">
              <ul className="right_side justify-content-end">
                <li
                  className="navbar_li"
                  onClick={() => handleClickNav("Haqqimizda")}
                >
                  Haqqımızda
                </li>
                <li
                  className="navbar_li"
                  onClick={() => handleClickNav("Xidmetler")}
                >
                  Xidmətlər
                </li>
                <li
                  className="navbar_li"
                  onClick={() => handleClickNav("Layiheler")}
                >
                  Layihələr
                </li>
                <li
                  className="navbar_li"
                  onClick={() => handleClickNav("Elaqe")}
                >
                  Əlaqə
                </li>
                {/* <li>
                  <select
                    id="languageSelect"
                    value={currentLanguage}
                    onChange={handleLanguageChange}
                  >
                    <option value="Az">Az</option>
                    <option value="En">En</option>
                  </select>
                </li> */}
                <Link to='/suggest' >
                <li>
                  <button>
                    <span>Sifariş et</span>
                  </button>
                </li></Link>
                <li className="nav_mini_li">
                  <div className={isOpen ? "hamburger-react-border" : ""}>
                    <Hamburger
                      toggled={isOpen}
                      toggle={setOpen}
                      // rounded={isOpen}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <nav id="nav_mobile" className={isOpen ? "open" : ""}>
        <ul>
          <li
            className="navbar_li"
            onClick={() => {
              handleClickNav("Haqqimizda");
              setOpen(false);
            }}
          >
            Haqqımızda
          </li>
          <li
            className="navbar_li"
            onClick={() => {
              handleClickNav("Xidmetler");
              setOpen(false);
            }}
          >
            Xidmətlər
          </li>
          <li
            className="navbar_li"
            onClick={() => {
              handleClickNav("Layiheler");
              setOpen(false);
            }}
          >
            Layihələr
          </li>
          <li
            className="navbar_li"
            onClick={() => {
              handleClickNav("Elaqe");
              setOpen(false);
            }}
          >
            Əlaqə
          </li>
        </ul>
        
      </nav>
      <Header />
     
    </>
  );
};

export default Navbar;
// I want his Home.jsx determines to slide to function in Navbar.jsx
