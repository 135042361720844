import React from "react";
import "../../../sass/footer.scss";

function Footer({ scrollToFirstSlide }) {
  return (
    <div className="listtotal">
      <div className="mini">
        <div className="data">
          <div className="head">
            <h1>Yeniliklərdən xəbərdar ol!</h1>
          </div>
          <div className="email">
            <form className="mt-4" action="">
              <input type="text" placeholder="Email" />

              <button type="submit" className="send">
                Göndər
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M25.0321 21.1871C24.503 21.1871 24.0642 20.7484 24.0642 20.2194L24.0642 9.30323L7.64865 25.7161C7.45507 25.9097 7.20987 26 6.96467 26C6.71947 26 6.47427 25.9097 6.28069 25.7161C5.90644 25.3419 5.90644 24.7226 6.28069 24.3484L22.6962 7.93548L11.7784 7.93548C11.2492 7.93548 10.8105 7.49677 10.8105 6.96774C10.8105 6.43871 11.2492 6 11.7784 6L25.0321 6C25.1612 6 25.2773 6.02581 25.4064 6.07742C25.6387 6.16774 25.8322 6.36129 25.9226 6.59355C25.9742 6.72258 26 6.83871 26 6.96774L26 20.2194C26 20.7484 25.5612 21.1871 25.0321 21.1871Z"
                    fill="#E4E4E4"
                  />
                </svg>
              </button>
            </form>
            <hr />
          </div>

          <div className="foot">
            <div>
              <table>
                <tr>
                  <td className="d-flex">Xidmətlər     </td>{" "}
             
                  <td>Layihələr</td>
                  <td>Haqqımızda</td>
                </tr>
                <tr>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=708422699"
                  >
                    Whatsapp   <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.2257 16.1497C18.8024 16.1497 18.4514 15.7987 18.4514 15.3755L18.4514 6.64258L5.31892 19.7729C5.16406 19.9277 4.9679 20 4.77174 20C4.57558 20 4.37941 19.9277 4.22455 19.7729C3.92515 19.4735 3.92515 18.9781 4.22455 18.6787L17.357 5.54839L8.62268 5.54839C8.19939 5.54839 7.84836 5.19742 7.84836 4.77419C7.84836 4.35097 8.19939 4 8.62268 4L19.2257 4C19.3289 4 19.4218 4.02065 19.5251 4.06194C19.7109 4.13419 19.8658 4.28903 19.9381 4.47484C19.9794 4.57806 20 4.67097 20 4.77419L20 15.3755C20 15.7987 19.649 16.1497 19.2257 16.1497Z"
                      fill="#E4E4E4"
                    />
                  </svg>
                  </a>
                   <a href="">Linkedin  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.2257 16.1497C18.8024 16.1497 18.4514 15.7987 18.4514 15.3755L18.4514 6.64258L5.31892 19.7729C5.16406 19.9277 4.9679 20 4.77174 20C4.57558 20 4.37941 19.9277 4.22455 19.7729C3.92515 19.4735 3.92515 18.9781 4.22455 18.6787L17.357 5.54839L8.62268 5.54839C8.19939 5.54839 7.84836 5.19742 7.84836 4.77419C7.84836 4.35097 8.19939 4 8.62268 4L19.2257 4C19.3289 4 19.4218 4.02065 19.5251 4.06194C19.7109 4.13419 19.8658 4.28903 19.9381 4.47484C19.9794 4.57806 20 4.67097 20 4.77419L20 15.3755C20 15.7987 19.649 16.1497 19.2257 16.1497Z"
                      fill="#E4E4E4"
                    />
                  </svg></a> 
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/bakusc.group/"
                  >
                    Instagram  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.2257 16.1497C18.8024 16.1497 18.4514 15.7987 18.4514 15.3755L18.4514 6.64258L5.31892 19.7729C5.16406 19.9277 4.9679 20 4.77174 20C4.57558 20 4.37941 19.9277 4.22455 19.7729C3.92515 19.4735 3.92515 18.9781 4.22455 18.6787L17.357 5.54839L8.62268 5.54839C8.19939 5.54839 7.84836 5.19742 7.84836 4.77419C7.84836 4.35097 8.19939 4 8.62268 4L19.2257 4C19.3289 4 19.4218 4.02065 19.5251 4.06194C19.7109 4.13419 19.8658 4.28903 19.9381 4.47484C19.9794 4.57806 20 4.67097 20 4.77419L20 15.3755C20 15.7987 19.649 16.1497 19.2257 16.1497Z"
                      fill="#E4E4E4"
                    />
                  </svg>
                  </a>
                </tr>
              </table>
            </div>
            <button className="arrow" onClick={() => scrollToFirstSlide()}>
              <svg
                className="child"
                width="53"
                height="70"
                viewBox="0 0 53 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="child2"
                  d="M28.012 9.15986L28.0122 6.9819L29.5446 8.52195L48.7936 27.8672C49.3758 28.4523 50.3547 28.4524 50.9372 27.8669C51.5197 27.2815 51.5196 26.2977 50.9375 25.7126L27.5702 2.22847C27.4376 2.09525 27.2888 1.99456 27.055 1.89389L27.055 1.89393L27.0471 1.89044C26.7087 1.7408 26.2884 1.74082 25.9499 1.89054L25.9499 1.89058L25.9419 1.894C25.7081 1.99471 25.5592 2.09546 25.4266 2.22875L2.05557 25.7167C1.47305 26.3021 1.47312 27.2859 2.0553 27.871C2.63748 28.4561 3.61638 28.4561 4.1989 27.8707L23.451 8.52234L24.9837 6.98194L24.9835 9.16039L24.9789 67.338C24.9788 68.1661 25.669 68.8597 26.4929 68.8596C26.9249 68.8596 27.2917 68.6893 27.5648 68.4148C27.8379 68.1403 28.0073 67.7717 28.0074 67.3375L28.012 9.15986Z"
                  fill="#E4E4E4"
                  stroke="#121212"
                  strokeWidth="1.8"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
