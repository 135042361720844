import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import "./../../../sass/Suggest.scss";
import Back from "./../../../Back";
import Submit from "../../../Submit";
import Success from "../Success/Success";

const Suggest = (props) => {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0gnm1xv", "template_1fszsue", form.current, {
        publicKey: "fAN_R-mSt1Y6IauHk",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 3000); // 3 seconds
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <section id="suggest">
      {isSuccess ? (
        <Success />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="suggest_left">
                <div className="writing_part">
                  <p>Xidmətləri</p>
                  <p>fürsətə</p>
                  <p>çevir</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="suggest_right">
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <input
                      type="text"
                      name="from_name"
                      placeholder="Ad"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email_id"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="phone_num"
                      placeholder="+994"
                      required
                    />
                  </div>
                  <div>
                    <select name="message" required>
                      <option>Saytların hazırlanması</option>
                      <option>Web Design</option>
                      <option>Saytların yenilənməsi</option>
                      <option>Keyfiyyət analizi</option>
                    </select>
                  </div>
                  <Submit />
                </form>
              </div>
            </div>
          </div>
        <Link to='/'>
          <Back /></Link>
        </div>
      )}
    </section>
  );
};

export default Suggest;
